

.dark-mode .bg-white {
  background-color: #1a202c; // Example dark mode background color
}
.dark-mode * {
  background-color: #1a202c; // Example dark mode background color
}
.dark-mode body {
  background-color: #1a202c; // Example dark mode background color
}

// input {
//   border-bottom: 1px solid gray !important;
// }

// .ltr {
//   label {
//     text-align: start !important;
//   }
// }
// .rtl {
//   label {
//     text-align: end !important;
//   }
// }

input.class-1 {
  background-color: white !important;
  border-color: red !important;
  :focus {
    border-color: transparent !important;
  }
}
.class-1 {
  .multiselect-dropdown {
    background-color: white !important;
    border:1px solid red !important
  }
}

// input.class-1  ::placeholder {

//  color: red;

// opacity: 1; /* Firefox */

// }
// input.class-1[type='tel']:focus{
//   border-color: red !important;
// }
.class-checkbox-err {
  background-color: white !important;
  border-color: red !important;
}

// .class-radio-err{
//   // background-color: #FDF2F2 !important;
// color: #F05252!important;
// }
.class-2 {
  border-color: white !important;
  border-color: green !important;
}


.class-2 {
  .multiselect-dropdown {
    border-color: white !important;
  border-color: green !important;
  }
}

.inFile,
.inFile-fill {
  color: white;
  padding: 22px 22px;
  border: 6px solid #000;
  border-radius: 50% !important;

  img {
    width: 50px;
  }
}

.inFile-fill {
  border: 6px solid #e8543e !important;
}

modal-container {
  display: flex !important;
  align-items: center !important;
  height: 100vh;
}

a {
  cursor: pointer !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  border: none !important;
}

a {
  text-decoration: none !important;
}

.rtl-input {
  direction: rtl;
}

// *** layout ***
.rtl {
  .text-left {
    text-align: right !important;
  }
  .startPageBody{
    margin-top: 40px;
    padding-top: 40px;
  }
  .mainBg{
    background-color: #FCFBFF;
  }
.startPagePadding{
  padding: 30px;
}
.mainAddressPadding{
  padding-top:20px !important;
  padding-right: 30px  !important;
  // padding-bottom: 11px  !important;
}
.underTitle{
  padding-bottom: 5px  !important;
}

.addressIconSpace{
  padding-right:20px  !important;
}

.underAddressLine{
  padding-top: 40px  !important;
}
.btnWithForm{
  height: 55px  !important;
}
.inputFilter{
  height: 55px  !important;
}
.btnFilter{
  height: 50px !important;
}
.cardsSpace{
  margin-bottom: 30px  !important;
}

.ProfilePadding{
  padding: 50px !important;
}
.ProfileBtn{
  height: 60px !important;
  // background-color: #1B2651 !important; //this class is in teacher & student & parent & shouldn't make color is fixed
  color:white !important;
}
.PrevProfileBtn{
  height: 60px !important;
  margin-top: 40px !important;
}
.PaginationProfileMargin{
  margin-top: 40px !important;
}
.flexFormDivAuth{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  .flexFormDivAuth2{
    display: block

  }
}
}
.ltr {
  .text-end {
    text-align: left !important;
  }
  .startPageBody{
    margin-top: 40px;
    padding-top: 40px;
  }
  .mainBg{
    background-color: #FCFBFF;
  }
.startPagePadding{
  padding: 30px;
}
.mainAddressPadding{
  padding-top:20px !important;
  padding-left: 30px  !important;
  // padding-bottom: 11px  !important;
}
.underTitle{
  padding-bottom: 5px  !important;
}

.addressIconSpace{
  padding-left:20px  !important;
}

.underAddressLine{
  padding-top: 40px  !important;
}
.btnWithForm{
  height: 55px  !important;
}
.inputFilter{
  height: 55px  !important;
}
.btnFilter{
  height: 50px !important;
}
.cardsSpace{
  margin-bottom: 30px  !important;
}

.ProfilePadding{
  padding: 50px !important;
}
.ProfileBtn{
  height: 60px !important;
  // background-color: #1B2651 !important; //this class is in teacher & student & parent & shouldn't make color is fixed
  color:white !important;
}
.PrevProfileBtn{
  height: 60px !important;
  margin-top: 40px !important;
}
.PaginationProfileMargin{
  margin-top: 40px !important;
}
.flexFormDivAuth{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  .flexFormDivAuth2{
    display: block
  }

}
}
@media (max-width: 767px) {
  .mat-expansion-panel-body{
    padding: 0 !important;
  }
.ltr{
  .ProfileBtn, button{
    height: auto !important;
  }
  .ProfilePadding{
    padding: 15px !important;
  }
}
.rtl{
  .mainAddressPadding {
    padding-right: 3px !important;
    text-align: right;

  }
  .addressIconSpace {
    padding-right: 3px !important;
    text-align: right;

  }
ul.startPagePadding {
  padding: 0px !important;

}

  .ProfilePadding{
    padding: 15px !important;
  }}

}

:host::ng-deep {

  .invalid-border{
    input {
      border: 0px !important;
      border: 1px solid red !important;
      background-color: transparent !important;
      font-size: 1.6rem !important;
      font-weight: bold !important;
      border-radius: 0 !important;
      height: 3.3rem !important;
    }
  }

}
