// /*  Animation css */
// @import '~animate.css/animate.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@import "primeicons/primeicons.css";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import   "node_modules/aos/dist/aos.css";
@import "theme/style";
/*  Themify icon scss */
@import "theme/themify";

/* Theme Scss */
@import "theme/variables";

:root {
  --theme-deafult: #b78f4f;
  --theme-deafult-studentWithClick: #faac04;
  --theme-deafult-ParentWithClick: #cd2028;
  --theme-deafult-TeacherWithClick: #1b2651;

  --theme-deafult-student: #fffaf0;
  --theme-deafult-Parent: #fff5f5;
  --theme-deafult-Teacher: #f1f3f6;
  --theme-Border: #dddddd;

  --secondary_color: #00263e;
  /* This gradient color only for gym and marijuana layout*/
  --theme-gradient1: #fcfbff;
  --theme-white: white;
}

body {
  background-color: var(--theme-gradient1);
  overflow-x: hidden;
  form {
    // input {
  //  color:  #1B2651;
    // }
input{
  margin: 0 !important;
}
label {
  font-family: $font_5_en, $font_1 !important;
  font-weight: normal !important;
  font-size: 16px !important;
  text-align: start;
  padding: 20px 0 8px 0 !important;
}
label.Accept{
  font-size: 14px !important;

}

  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Regular, sans-serif;
}


.rtl {
  // .contantMessageDiv,.mymessageName{
  //   text-align: right !important;
  // }
.teacherRate{
  direction: ltr !important;
}
  body {
    background-color: var(--theme-gradient1);
    overflow-x: hidden;
    form {
      // input {
    //  color:  #1B2651;
      // }
  input{
    margin: 0 !important;
  }
  label {
    font-family: $font_5_en, $font_1 !important;
    font-weight: normal !important;
    font-size: 16px !important;
    text-align: start;
    padding: 20px 0 8px 0 !important;
  }
  label.Accept{
    font-size: 14px !important;

  }

    }
  }
  .rtl-p{
    text-align: right !important;
    direction: rtl !important;
  }
.NumColor{
  direction: rtl !important;
  flex-direction: row-reverse;
  span{
    margin-right: 5px;
  }
}
  .TeacherListCard{
    height: auto;
    border-radius:100em  841em   841em 100em !important;
      padding-right: 3%;
      padding-top: 1%;
      border: solid 1px #FA0;
      .teacherImage{
        max-height: 200px;
        max-width: 200px;
        overflow:hidden;
        img{
          width: 100%;
        }
      }

  }
  .Anywhere{
    background-image: url('~src/assets/images/Anonymous/Anywhere-ar.jpg') !important;
  }
  h1,
    h3,
    h5,
    h4
    h2
    h6,
    // span,
    p,
    a,
    #product-detail p,
    .border-product p,
    .welcome-msg p:first-child ,.theme-form label ,.card-body p,h6 .sec-title h2.featured-timeline  span ,.Lama-Regular ,thead tr th {
    font-family: $font_0_en, $font_1 !important;

  }

  h1,
  .product-detail-h5,
  .product-detail a h4,
  .card-body h4,
  .media h4,
  h2 .color1.timeline span,
  form h2 {
    font-family: $font_4_en, $font_1 !important;
    font-weight: normal !important;
  }

  h4,
  .pricing-features li,
  .btn,

  button,
  select,
  optgroup,
  textarea,
  .product-detail-p,
  .welcome-msg p span,
  .age-content p,
  .welcome-msg p:nth-child(1),
  .single-pricing-box p,
  .media p,.Lama-Ligh,
  .light-layout.light-layout-tow,
  .address-footer {
    font-family: $font_2_en, $font_1 !important;
    font-weight: normal !important;
  }

  .download-home,
  .Lama-bold {
    font-family: $font_4_en, $font_1 !important;
    font-weight: normal !important;
    color: #1b2651 !important;
  }

  .compare a,
  .welcome-msg p:nth-child(2),
  .block-content a,
  .header-contact li,
  .Lama-Medium,
  button,
  p {
    font-family: $font_3_en, $font_1 !important;
    font-weight: normal !important;
  }
  #ar span {
    font-family: $font_0_en, $font_1 !important;
  }

  #Sidebar-li button span {
    font-family: $font_0_en, $font_1 !important;
  }
  .Lama-SemiBold {
    font-family: $font_5_en, $font_1 !important;
    font-weight: normal !important;
  }



[type='text']::placeholder, [type='email']::placeholder, [type='url']::placeholder, [type='password']::placeholder, [type='number']::placeholder, [type='date']::placeholder, [type='datetime-local']::placeholder, [type='month']::placeholder, [type='search']::placeholder, [type='tel']::placeholder, [type='time']::placeholder, [type='week']::placeholder, [multiple]::placeholder, textarea::placeholder, select
,input::placeholder {
  font-family: $font_0_en, $font_1 !important;
    font-size: 14px !important;
    color :#868B9F !important

}


.ng-dropdown-panel.ng-select-bottom,
ng-select .form-control {
  font-size: 14px !important;
  font-family: $font_0_en, $font_1 !important;

  cursor: pointer !important;
}

table {
  border : 1px solid #DDDDDD;
  margin-bottom: 5px;
  thead {
    tr td,
    tr th,
    th td span {
      font-family: $font_5_en, $font_1 !important;
      font-weight: normal !important;
      font-size: 12px !important;
    }
  }

  tbody {
    tr,
    tr div,
    p {
      th,
      td {
        font-size: 12px !important;
        font-family: $font_0_en, $font_1 !important;
        font-weight: normal !important;
        color: var(--theme-deafult-TeacherWithClick) !important;
      }
    }
  }
}
.ImgSize {
  width: 200px;
  height: 200px;
}


select
,
.ng-select.ng-select-single .ng-select-container, ng-multiselect-dropdown ,.multiselect-dropdow{
  padding: 10px !important;
}
}

.ltr {
  body {
    background-color: var(--theme-gradient1);
    overflow-x: hidden;
    form {
      // input {
    //  color:  #1B2651;
      // }
  input{
    margin: 0 !important;
  }
  label {
    font-family: $font_5_en, $font_1 !important;
    font-weight: normal !important;
    font-size: 16px !important;
    text-align: start;
    padding: 20px 0 8px 0 !important;
  }
  label.Accept{
    font-size: 14px !important;

  }

    }
  }

  .Anywhere{
    background-image: url('~src/assets/images/Anonymous/app.jpg');
  }

  h1,
    h3,
    h5,
    h4
    h2
    h6,
    // span,
    p,
    a,
    #product-detail p,
    .border-product p,
    .welcome-msg p:first-child ,.theme-form label ,.card-body p,h6 .sec-title h2.featured-timeline  span ,.Lama-Regular ,thead tr th {
    font-family: $font_0_en, $font_1 !important;
  }

  h1,
  .product-detail-h5,
  .product-detail a h4,
  .card-body h4,
  .media h4,
  h2 .color1.timeline span,
  form h2 {
    font-family: $font_4_en, $font_1 !important;
    font-weight: normal !important;
  }

  h4,
  .pricing-features li,
  .btn,

  button,
  select,
  optgroup,
  textarea,
  .product-detail-p,
  .welcome-msg p span,
  .age-content p,
  .welcome-msg p:nth-child(1),
  .single-pricing-box p,
  .media p,.Lama-Ligh,
  .light-layout.light-layout-tow,
  .address-footer {
    font-family: $font_2_en, $font_1 !important;
    font-weight: normal !important;
  }

  .download-home,
  .Lama-bold {
    font-family: $font_4_en, $font_1 !important;
    font-weight: normal !important;
    color: #1b2651 !important;
  }

  .compare a,
  .welcome-msg p:nth-child(2),
  .block-content a,
  .header-contact li,
  .Lama-Medium,
  button,
  p {
    font-family: $font_3_en, $font_1 !important;
    font-weight: normal !important;
  }
  #ar span {
    font-family: $font_0_en, $font_1 !important;
  }

  #Sidebar-li button span {
    font-family: $font_0_en, $font_1 !important;
  }
  .Lama-SemiBold {
    font-family: $font_5_en, $font_1 !important;
    font-weight: normal !important;
  }



[type='text']::placeholder, [type='email']::placeholder, [type='url']::placeholder, [type='password']::placeholder, [type='number']::placeholder, [type='date']::placeholder, [type='datetime-local']::placeholder, [type='month']::placeholder, [type='search']::placeholder, [type='tel']::placeholder, [type='time']::placeholder, [type='week']::placeholder, [multiple]::placeholder, textarea::placeholder, select
,input::placeholder {
  font-family: $font_0_en, $font_1 !important;
    font-size: 14px !important;
    color :#868B9F !important

}


.ng-dropdown-panel.ng-select-bottom,
ng-select .form-control {
  font-size: 14px !important;
  font-family: $font_0_en, $font_1 !important;

  cursor: pointer !important;
}

table {
  border : 1px solid #DDDDDD;
  margin-bottom: 5px;
  thead {
    tr td,
    tr th,
    th td span {
      font-family: $font_5_en, $font_1 !important;
      font-weight: normal !important;
      font-size: 12px !important;
    }
  }

  tbody {
    tr,
    tr div,
    p {
      th,
      td {
        font-size: 12px !important;
        font-family: $font_0_en, $font_1 !important;
        font-weight: normal !important;
        color: var(--theme-deafult-TeacherWithClick) !important;
      }
    }
  }
}
.ImgSize {
  width: 200px;
  height: 200px;
}

}
/* Ng-select*/
@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select .ng-select-container {
  background-color: var(--theme-gradient1) !important;
}

@import "devextreme/dist/css/dx.common.css";
@import "devextreme/dist/css/dx.light.css";

#available-doctor-appointments .owl-theme .owl-nav {
  position: relative !important;
  top: -125px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  [class*="owl-"] {
    background: var(--theme-deafult-studentWithClick);
    cursor: url("../images/Icons/MOUSE.svg"), auto !important;
    color: white !important;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  .owl-prev {
    left: 0;
  }
  .owl-next {
    right: 0;
  }
}


.global-card .owl-theme .owl-nav {
  position: relative !important;
  top: -10px;
  display: flex !important;
  justify-content: center;
  [class*="owl-"] {
    color: #1b2651;
    background: transparent;
    // margin: 0 50px;
  }
}



.ng-dropdown-panel.ng-select-bottom,
ng-select .form-control {
  text-align: justify;
}
// @media (min-width: 1200px){
//   .dropdown-btn{
//     width:908px !important

//   }
// }

@media (max-width: 768px) {
  h2,
  h3 {
    font-size: 15px !important;
  }
  label,
  thead tr th,
  th div p {
    font-size: 10px !important;
  }

  // thead tr th,
  // tbody tr td {
  //   padding: 0 !important;
  // }
  tbody tr td,
  td span {
    font-size: 9px !important;
  }
  .ng-dropdown-panel.ng-select-bottom,
  ng-select .form-control {
    font-size: 9px !important;
  }
  .main-home {
    display: flex;
    flex-direction: column-reverse !important;
  }

  .ImgSize{
    width: 150px !important;
    height: 100px !important;
  }
}

// custom owl-dot.active
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #1b2651; /* Change to the desired background color on hover */
}


[type='tel'],
[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'],   [type='time'], [type='week'], [multiple],  select
,
.ng-select.ng-select-single .ng-select-container, ng-multiselect-dropdown ,.multiselect-dropdow{
  height: 55px !important;
  display: flex;
  align-items: center;
}

textarea{
  height: 150px !important;

}

 // style  for Otp code
:host::ng-deep {
  ng-otp-input{
    display: flex;
      width: 100%;
      justify-content: center;

      .ng-otp-input-wrapper {
        display: flex;
        justify-items: center;
        align-items: center;
        .otp-input{
          width: 45px !important;
        }
      }
  }
    .invalid-border{
      input {
        border: 0px !important;
        border: 1px solid red !important;
        background-color: transparent !important;
        font-size: 1.6rem !important;
        font-weight: bold !important;
        border-radius: 0 !important;
        height: 3.3rem !important;
      }
    }

  }

  mat-expansion-panel{
    overflow: visible !important;
  }


.selected-item{
  max-width: 100% !important;
}

 .multiselect-dropdow, .dropdown-btn{
  padding: 16px 12px !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow-y: auto;
    max-height: 86px;
    display: flex;
    flex-wrap: nowrap;

}
 .filter-textbox{
  padding: 0 !important;
 }

