@font-face {
  font-family: 'BoldEn';
  src: url('../../fonts/Lama/LamaSans-Bold.otf') format('truetype');
  font-weight: normal; /* Medium */
  font-style: normal;
}
@font-face {
  font-family: 'SemiBoldEn';
  src: url('../../fonts/Lama/LamaSans-SemiBold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'LightEn';
  src: url('../../fonts/Lama/LamaSans-Light.otf') format('truetype');
  font-weight: normal; /* Light */
  font-style: normal;
}
@font-face {
  font-family: 'MediumEn';
  src: url('../../fonts/Lama/LamaSans-Medium.otf') format('truetype');
  font-weight: normal; /* Medium */
  font-style: normal;
}


@font-face {
  font-family: 'RegularEn';
  src: url('../../fonts/Lama/LamaSans-Regular.otf') format('truetype');
  font-weight: normal; /* Regular */
  font-style: normal;
}




/// Cairo AR

// @font-face {
//   font-family: 'BoldAR';
//   src: url('../../fonts/Cairo/static/Cairo-Bold.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'SemiBoldAR';
//   src: url('../../fonts/Cairo/static/Cairo-SemiBold.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'LightAR';
//   src: url('../../fonts/Cairo/static/Cairo-Light.ttf') format('truetype');

//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'MediumAR';
//   src: url('../../fonts/Cairo/static/Cairo-Medium.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }


// @font-face {
//   font-family: 'RegularAR';
//   src: url('../../fonts/Cairo/static/Cairo-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }


