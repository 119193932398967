/* You can add global styles to this file, and also import other style files */
.MrsCoolBtn {
  background-color: #FFAA00;
  color: white;
  border: #FFAA00;
}

a, .min-width-small, .customColor .radio-label, button, #Sidebar-li button span {
  cursor: pointer !important;
}

/* styles.css or your global stylesheet */
.valid-form {
  background-color: var(--theme-deafult-TeacherWithClick);
  color: #fff; /* Assuming you want white text for valid forms */
}

.invalid-form {
  background-color: #DDDFE5;
  color: #868B9F;
}

ng-option {
  overflow-x: auto;
}

p {
  font-family: Lato;
}

.scroller {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 0.6em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--theme-deafult-TeacherWithClick);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--theme-deafult-studentWithClick);
}

.fixed-width-ng-select {
  width: 300px;
}

[type=date] {
  display: block !important;
  font-size: medium;
  color: #1b2651;
}

[type=time] {
  display: block !important;
  font-size: medium;
  color: #1b2651;
}

.Notice {
  font-size: 17px !important;
}